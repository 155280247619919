import api from '~api/maps'
import MakeRequest from '~api/request'

import type { MapsSavedAddress } from '~types/accountStore'
import type { DistrictsPrice } from '~types/addressStore'
import type { AddressCoordinates } from '~types/clientStore'
import type { StoreState } from '~types/common'
import type {
  Geocode,
  GeoObject,
  LngLat,
  MapsStore,
  MapType,
  SearchFeature,
  SuggestResponseItem,
  YMapBounds
} from '~types/mapsStore'

import type { VectorCustomization, YMap, YMapFeature, YMapMarker } from '@yandex/ymaps3-types'
import type { DomEvent, DomEventHandlerObject } from '@yandex/ymaps3-types/imperative/YMapListener'

import { computed, ref } from 'vue'

import { type GUID, RequestMethod, useCommon } from '@arora/common'
import { defineStore } from 'pinia'
import robustPointInPolygon from 'robust-point-in-polygon'

type polygonEntry = { district: DistrictsPrice; polygon: LngLat[][] }
type addressGeoData = {
  geoDistrict: string[]
  geoRegion: string[]
  street: string
  house: string
  geoCity: string
}

const defaultCustomization: VectorCustomization = [
  {
    tags: {
      any: ['cemetery', 'medical', 'fuel_station']
    },
    stylers: {
      visibility: 'off'
    }
  },
  {
    tags: {
      any: ['water']
    },
    elements: 'geometry',
    stylers: [
      {
        lightness: -0.2
      },
      {
        opacity: 0.75
      }
    ]
  },
  {
    tags: {
      any: ['park', 'road_limited']
    },
    elements: 'label',
    stylers: {
      visibility: 'off'
    }
  },
  {
    tags: 'road',
    elements: 'geometry.outline',
    stylers: {
      opacity: 0.35
    }
  },
  {
    tags: 'road',
    elements: 'geometry.fill',
    stylers: {
      saturation: -0.35
    }
  },
  {
    tags: 'road',
    elements: 'label.icon',
    stylers: [
      {
        saturation: -0.25
      }
    ]
  },
  {
    tags: {
      any: ['transit']
    },
    stylers: [
      {
        scale: 0.85
      }
    ]
  },
  {
    tags: {
      any: ['major_landmark']
    },
    stylers: {
      scale: 1.15
    }
  },
  {
    tags: {
      any: [
        'geographic_line',
        'bathymetry',
        'traffic_light',
        'transit_stop',
        'crosswalk',
        'ferry',
        'road_construction',
        'transit_schema'
      ]
    },
    stylers: {
      visibility: 'off'
    }
  }
]

export const useMapsStore = defineStore('mapsStore', (): MapsStore => {
  const YandexMaps = ref<StoreState<YMapBounds>>({
    data: null,
    error: null,
    state: null
  })
  const key = ref<string | null>(null)

  const tilt = computed<number>(() => {
    const appConfig = useAppConfig()

    return appConfig.VueSettingsPreRun.YaMapsDisableTilt ? 0 : Math.PI / 4
  })

  const mapsUnref: Record<string, YMap | null> = {}
  const deliveryMarkers: Record<string, YMapMarker> = {}
  const mapsTypes: Record<string, MapType> = {}
  const polygons: Record<GUID, polygonEntry> = {}

  async function initYandexMaps(): Promise<void> {
    if (YandexMaps.value.state !== 'success' && YandexMaps.value.state !== 'loading') {
      await loadYandexMaps()
    }
  }

  async function loadYandexMaps(): Promise<void> {
    YandexMaps.value.state = 'loading'

    const appConfig = useAppConfig()
    const { eventEmit } = useEmitter()

    try {
      const yandexKey = appConfig.RestaurantSettingsPreRun.YandexMapKey
      if (!yandexKey) throw new Error('yandex API key is undefined')

      key.value = (await api.loadYandexMap(yandexKey)) ?? key.value

      await ymaps3.ready
      ymaps3.strictMode = true
      YandexMaps.value.state = 'success'
      eventEmit('ymaps3-loaded')
    } catch (error) {
      YandexMaps.value.error = error
      YandexMaps.value.state = 'error'
    }
  }

  function addDeliveryPoint(uid: string, color: string, longitude: number, latitude: number): void {
    if (deliveryMarkers[uid] && mapsUnref[uid]) {
      mapsUnref[uid].removeChild(deliveryMarkers[uid])
    }

    const markerElement = document.createElement('div')
    markerElement.id = `delivery-point-${uid}`
    markerElement.style.color = color

    deliveryMarkers[uid] = new ymaps3.YMapMarker(
      {
        id: 'v-delivery-point',
        coordinates: [longitude, latitude]
      },
      markerElement
    )

    mapsUnref[uid]?.addChild(deliveryMarkers[uid])
  }

  function checkCoords(coordinates: LngLat): boolean {
    for (const [_, type] of Object.entries(mapsTypes)) {
      if (type === 'delivery' || type === 'saved') {
        for (const [_, distrPoly] of makePolygonEntries()) {
          //robustPointInPolygon returns following:
          //-1 if point is contained inside loop
          // 0 if point is on the boundary of loop
          // 1 if point is outside loop
          if (
            distrPoly.polygon.some(
              (poly) =>
                robustPointInPolygon(poly as [[number, number]], coordinates as [number, number]) !== 1
            )
          ) {
            return true
          }
        }
      }
    }

    return false
  }

  function makePolygonEntries(): [string, polygonEntry][] {
    return Object.entries(polygons).sort((a, b) => b[1].district.Weight - a[1].district.Weight)
  }

  function getAddressGeoData(geocode: GeoObject, distrPoly: polygonEntry): addressGeoData {
    const { stringIsNullOrWhitespace } = useCommon()

    const geoDistrict: string[] = []
    const geoRegion: string[] = []
    const geoCity: string[] = []
    const locality: string[] = []
    let hasSourceDistrict = false

    let house = '',
      province = '',
      street = ''

    for (const component of geocode.metaDataProperty.GeocoderMetaData.Address.Components) {
      switch (component.kind) {
        case 'area':
          geoRegion.push(component.name)
          break
        case 'country':
          geoRegion.push(component.name)
          break
        case 'house':
          house = component.name
          break
        case 'district':
          geoDistrict.push(component.name)
          geoRegion.push(component.name)
          hasSourceDistrict = true
          break
        case 'locality':
          locality.push(component.name)
          geoCity.push(component.name)
          break
        case 'province':
          geoRegion.push(component.name)
          province = component.name
          break
        case 'airport':
        case 'railway_station':
        case 'street':
          street = component.name
          break
      }
    }

    if (locality.length === 0) {
      locality.push(province)
    }

    if (stringIsNullOrWhitespace(street)) {
      if (
        geoDistrict.length > 0 &&
        !stringIsNullOrWhitespace(geoDistrict[geoDistrict.length - 1]) &&
        hasSourceDistrict
      ) {
        street = geoDistrict[geoDistrict.length - 1]
      } else if (locality.length > 0) street = locality[locality.length - 1]
    } else if (!distrPoly.district.ClearLocalityIfThereIsAStreet) {
      street = `${street}, ${locality[locality.length - 1]}`
    }

    if (distrPoly.district.ClearStreetInComponent)
      street = street
        .replaceAll('улица', '')
        .replaceAll('ул.', '')
        .replaceAll('ул ', '')
        .replaceAll(' ул', '')
        .replaceAll('вулиця', '')
        .replaceAll('вул.', '')
        .replaceAll('вул ', '')
        .replaceAll(' вул', '')
        .replaceAll('street', '')
        .replaceAll('st.', '')
        .replaceAll('st ', '')
        .replaceAll(' st', '')
        .trim()

    return {
      geoDistrict,
      geoRegion,
      street,
      house,
      geoCity: geoCity.length > 0 ? geoCity.join(', ') : geoRegion[geoRegion.length - 1]
    }
  }

  function updateDeliveryCoordsWithGeocode(
    coordinates: LngLat | undefined,
    geocode: GeoObject
  ): boolean {
    if (!coordinates) return false

    updatePointByType(coordinates, 'delivery')

    for (const [uid, type] of Object.entries(mapsTypes)) {
      if (type === 'delivery') {
        for (const [districtId, distrPoly] of makePolygonEntries()) {
          //robustPointInPolygon returns following:
          //-1 if point is contained inside loop
          // 0 if point is on the boundary of loop
          // 1 if point is outside loop
          if (
            distrPoly.polygon.some(
              (poly) =>
                robustPointInPolygon(poly as [[number, number]], coordinates as [number, number]) !== 1
            )
          ) {
            const clientStore = useClientStore()

            const terminal = distrPoly.district.Departments[0].Terminals[0]

            if (!terminal.Active) {
              return false
            }

            deliveryMarkers[uid].element.style.color = distrPoly.district.PolygonConfiguration.FillColor
            const { geoDistrict, geoRegion, street, house, geoCity } = getAddressGeoData(
              geocode,
              distrPoly
            )

            const addressCoordinates: AddressCoordinates = {
              Longitude: coordinates[0],
              Latitude: coordinates[1]
            }

            clientStore
              .updateDeliveryAddress(
                {
                  addressCoordinates,
                  street,
                  house,
                  geoCity,
                  geoDistrict: geoDistrict.join(', '),
                  geoRegion: geoRegion.join(', '),
                  string: geoDistrict.join(', '),
                  cityId: distrPoly.district.CityID as GUID,
                  departmentId: distrPoly.district.Departments[0].ID,
                  districtId: districtId as GUID,
                  terminalId: terminal.ID
                },
                false
              )
              .then(() => changeYMapCenter(uid, coordinates))

            return true
          }
        }
      }
    }
    return false
  }

  function updatePointByType(coordinates: LngLat | undefined, typeOfMap: MapType): void {
    for (const [uid, type] of Object.entries(mapsTypes)) {
      if (type === typeOfMap && coordinates) {
        deliveryMarkers[uid].update({ coordinates: coordinates })
      }
    }
  }

  function updateSavedAddressCoordsWithGeocode(
    coordinates: LngLat | undefined,
    geocode: GeoObject,
    result: MapsSavedAddress
  ): MapsSavedAddress {
    if (!coordinates) return result

    updatePointByType(coordinates, 'saved')

    for (const [uid, type] of Object.entries(mapsTypes)) {
      if (type === 'saved') {
        for (const distrPoly of Object.values(polygons)) {
          //robustPointInPolygon returns following:
          //-1 if point is contained inside loop
          // 0 if point is on the boundary of loop
          // 1 if point is outside loop
          if (
            distrPoly.polygon.some(
              (poly) =>
                robustPointInPolygon(poly as [[number, number]], coordinates as [number, number]) !== 1
            )
          ) {
            const terminal = distrPoly.district.Departments[0].Terminals[0]
            if (!terminal.Active) {
              return result
            }

            deliveryMarkers[uid].element.style.color = distrPoly.district.PolygonConfiguration.FillColor
            const { geoDistrict, geoRegion, street, house, geoCity } = getAddressGeoData(
              geocode,
              distrPoly
            )

            result.AddressCoordinates = [coordinates[0], coordinates[1]]
            result.Street = street
            result.House = house
            result.City = geoCity
            result.Region = geoRegion.join(', ')
            result.District = geoDistrict.join(', ')
            changeYMapCenter(uid, coordinates)
          }
        }
      }
    }

    return result
  }

  function makeYMapPolygonCoords(district: DistrictsPrice): LngLat[][] {
    if (polygons[district.ID]) return polygons[district.ID].polygon

    const polygon = district.PolygonConfiguration.Coordinates.map((twoArray) =>
      twoArray.map((array) => {
        const result: LngLat = [array[1] ?? 0, array[0] ?? 0]

        return result
      })
    )

    polygons[district.ID] = {
      district: district,
      polygon: polygon
    }

    return polygon
  }

  function makeYMapBounds(threeArray: number[][][]): YMapBounds {
    const bounds: YMapBounds = {
      minLatitude: 9999,
      maxLatitude: 0,
      minLongitude: 9999,
      maxLongitude: 0
    }
    for (const twoArray of threeArray) {
      for (const array of twoArray) {
        if (array[0] > bounds.maxLatitude) bounds.maxLatitude = array[0]
        if (array[1] > bounds.maxLongitude) bounds.maxLongitude = array[1]

        if (array[0] < bounds.minLatitude) bounds.minLatitude = array[0]
        if (array[1] < bounds.minLongitude) bounds.minLongitude = array[1]
      }
    }

    return bounds
  }

  async function makeYMap(
    uid: string,
    type: MapType,
    bounds: YMapBounds,
    clickCallback = async (_object: DomEventHandlerObject, _event: DomEvent) => {
      /*unused by default*/
    }
  ): Promise<void> {
    await new Promise((r) => {
      setTimeout(r, 400) //await for previous map to be properly destroyed
    })

    const appConfig = useAppConfig()
    const menuStore = useMenuStore()

    const element = document.getElementById(uid)

    if (!element) throw new Error(`can't find element with id: ${uid}`)

    mapsTypes[uid] = type

    mapsUnref[uid] = new ymaps3.YMap(element, {
      behaviors: appConfig.VueSettingsPreRun.YaMapsEnableZoomOnScroll
        ? ['drag', 'scrollZoom', 'pinchZoom', 'dblClick', 'magnifier']
        : ['drag', 'pinchZoom', 'dblClick', 'magnifier'],
      mode: 'vector',
      location: {
        center: [37.623082, 55.75254],
        zoom: Number.parseInt(appConfig.VueSettingsPreRun.YaMapsZoom)
      },
      showScaleInCopyrights: true
    })

    mapsUnref[uid].addChild(
      new ymaps3.YMapDefaultFeaturesLayer({
        visible: true
      })
    )
    mapsUnref[uid].addChild(
      new ymaps3.YMapListener({
        onClick: clickCallback
      })
    )

    const darkTheme = menuStore.CurrentGroup?.UseAlternateTheme
      ? appConfig.VueSettingsPreRun.Theme.AlternativeTheme.DarkTheme
      : appConfig.VueSettingsPreRun.Theme.DefaultTheme.DarkTheme

    const customization = [...defaultCustomization]

    if (type === 'delivery' || type === 'saved') {
      customization.push({
        tags: {
          any: ['food_and_drink']
        },
        stylers: {
          visibility: 'off'
        }
      })
    }

    switch (appConfig.VueSettingsPreRun.YaMapsType) {
      case 'map':
        mapsUnref[uid].addChild(
          new ymaps3.YMapDefaultSchemeLayer({
            visible: true,
            theme: darkTheme ? 'dark' : 'light',
            customization: customization
          })
        )
        break
      case 'satellite':
        mapsUnref[uid].addChild(
          // @ts-expect-error YMapDefaultSatelliteLayer is removed from types
          new ymaps3.YMapDefaultSatelliteLayer({
            visible: true
          })
        )
        break
    }

    if (appConfig.VueSettingsPreRun.YaMapsShowZoomControl) {
      const { YMapZoomControl } = await ymaps3.import('@yandex/ymaps3-controls@0.0.1')

      mapsUnref[uid].addChild(
        new ymaps3.YMapControls({ position: 'right' }).addChild(new YMapZoomControl({}))
      )
    }
    if (appConfig.VueSettingsPreRun.YaMapsEnableAutoDetect) {
      const { YMapGeolocationControl } = await ymaps3.import('@yandex/ymaps3-controls@0.0.1')

      mapsUnref[uid].addChild(
        new ymaps3.YMapControls({ position: 'left' }).addChild(new YMapGeolocationControl({}))
      )
    }

    mapsUnref[uid].update({
      location: {
        bounds: [
          [bounds.minLongitude - tilt.value / 25, bounds.minLatitude - tilt.value / 25],
          [bounds.maxLongitude + tilt.value / 25, bounds.maxLatitude + tilt.value / 25]
        ]
      }
    })

    setTimeout(() => tiltMap(uid), 300)
  }

  function unmakeYMap(uid: string): void {
    delete mapsTypes[uid]
    delete deliveryMarkers[uid]

    if (mapsUnref[uid]) {
      for (const child of mapsUnref[uid].children) {
        mapsUnref[uid].removeChild(child)
      }
      mapsUnref[uid].destroy()
      mapsUnref[uid] = null
    }

    delete mapsUnref[uid]
  }

  function addYMapsFeatures(uid: string, features: YMapFeature[] | YMapMarker[]): void {
    if (!mapsUnref[uid]) {
      console.error('mapsUnref[uid] is null', uid)
      return
    }
    for (const feature of features) {
      mapsUnref[uid].removeChild(feature)
      mapsUnref[uid].addChild(feature)
    }
  }

  function changeYMapCenter(uid: string, coordinates: LngLat): void {
    if (!mapsUnref[uid]) {
      console.error('mapsUnref[uid] is null', uid)
      return
    }

    mapsUnref[uid].update({
      location: {
        center: coordinates,
        duration: 750
      }
    })
  }

  function tiltMap(uid: string): void {
    if (!mapsUnref[uid]) {
      console.error('mapsUnref[uid] is null', uid)
      return
    }

    mapsUnref[uid].update({
      camera: {
        azimuth: mapsUnref[uid].azimuth,
        tilt: tilt.value,
        duration: 250
      }
    })
  }

  function changeYMapBounds(uid: string, bounds: YMapBounds, duration = 850): void {
    if (!mapsUnref[uid]) {
      console.error('mapsUnref[uid] is null', uid)
      return
    }

    mapsUnref[uid].update({
      location: {
        bounds: [
          [bounds.minLongitude - tilt.value / 100, bounds.minLatitude - tilt.value / 100],
          [bounds.maxLongitude - tilt.value / 100, bounds.maxLatitude - tilt.value / 100]
        ],
        duration: duration
      }
    })
  }

  function saveYMapBounds(bounds: YMapBounds): void {
    YandexMaps.value.data = bounds
  }

  async function geocodeYMapSuggest(text: string): Promise<SuggestResponseItem[]> {
    const addressStore = useAddressStore()
    const { stringIsNullOrWhitespace } = useCommon()
    const properBounds = makeProperBounds()

    const result = [] as SuggestResponseItem[]
    const promises = []

    for (const city of addressStore.ActiveCities) {
      const cityTitle = stringIsNullOrWhitespace(city.TitleForGeocoding)
        ? city.Title
        : city.TitleForGeocoding

      promises.push(
        ymaps3
          .suggest({
            text: text.includes(cityTitle) ? text : `${cityTitle} ${text}`,
            bounds: properBounds
          })
          .then((resp) => {
            const respFiltered = resp.filter((item) => item.value !== cityTitle)
            result.push(...respFiltered)
          })
      )
    }

    await Promise.all(promises)

    return result
  }

  async function geocodeYmap(text: string, kind: 'house' | 'street' | null): Promise<Geocode> {
    const appConfig = useAppConfig()

    const yandexKey = appConfig.RestaurantSettingsPreRun.YandexMapKey
    if (!yandexKey) throw new Error('yandex API key is undefined')

    const properBounds = makeProperBounds()

    const addParameters: Map<string, number | string> = new Map()

    if (properBounds) {
      addParameters.set('rspn', 1)
      addParameters.set('bbox', `${properBounds[0].join(',')}~${properBounds[1].join(',')}`)
    }

    if (kind) addParameters.set('kind', kind)

    return await MakeRequest<Geocode>(
      'https://geocode-maps.yandex.ru/1.x/',
      new Map([
        ['apikey', key.value ?? yandexKey],
        ['format', 'json'],
        ['geocode', text],
        ['results', appConfig.VueSettingsPreRun.StreetListAutocompleteNumber],
        ...addParameters
      ]),
      RequestMethod.GET,
      null,
      false,
      false
    )
  }

  async function geocodeYMapSearch(text: string): Promise<SearchFeature[]> {
    const properBounds = makeProperBounds()

    return await ymaps3.search({
      text: text,
      bounds: properBounds,
      strictBounds: properBounds !== undefined
    })
  }

  function makeProperBounds(): [LngLat, LngLat] | undefined {
    let properBounds: [LngLat, LngLat] | undefined

    if (YandexMaps.value.data) {
      properBounds = [
        [YandexMaps.value.data.minLongitude, YandexMaps.value.data.minLatitude],
        [YandexMaps.value.data.maxLongitude, YandexMaps.value.data.maxLatitude]
      ]
    } else {
      console.warn('no bounds set, searching entire map')
    }

    return properBounds
  }

  function clearAllMaps(): void {
    for (const uid of Object.keys(mapsUnref)) {
      unmakeYMap(uid)
    }
  }

  return {
    YandexMaps,
    tilt,

    initYandexMaps,

    makeYMap,
    unmakeYMap,
    clearAllMaps,

    addDeliveryPoint,
    addYMapsFeatures,
    changeYMapBounds,
    changeYMapCenter,
    checkCoords,
    geocodeYMapSearch,
    geocodeYMapSuggest,
    geocodeYmap,
    makeYMapBounds,
    makeYMapPolygonCoords,
    saveYMapBounds,
    tiltMap,
    updateDeliveryCoordsWithGeocode,
    updatePointByType,
    updateSavedAddressCoordsWithGeocode
  }
})
